import instrum from './../../img/forsite/Articels/article5.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import FirstScreen from "../../FirstScreen/ContainerOne";
import FiveScreen from '../../FiveScreen/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>UA</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/rus/articelsix'>RU</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }
function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Інструменти для ремонту вікон</h2>
            <img className="quilon" src={instrum} title='фурнітура' alt="фурнітура"/>
            <p>
            Якісне регулювання можливе при використанні відповідних інструментів. Необхідно підготувати наступний набір:
            </p>
            <ul className="furnitul">
                <li>Ключ-шестигранник на 4 мм – основний інструмент у налаштуванні. Форма великого значення немає, зазвичай використовуються Г-образные ключі. Існують і професійні ключі, з вигином для зручнішої роботи</li>
                <li>Зіркоподібні насадки на викрутку. Дані інструменти маркуються позначеннями Т чи ТХ, що використовуються не завжди. Вони можуть знадобитися тільки для окремих типів віконної фурнітури.</li>
                <li>Спеціальна ручка для вікон.</li>
                <li>Хрестова викрутка розміру 3 або 4. Найчастіше фурнітура кріпиться на вікні болтами, що відкручуються стандартною хрестовою викруткою. Такий інструмент знадобиться під час заміни або регулювання ручки.</li>
                <li>Плоскогубці.</li>
                <li>Придатне для фурнітури мастило (BL-40 або WD-40).</li>
                <li>Шуруповерт.</li>
                <li>Свердла різного діаметра.</li>
            </ul>
            <p>
            У сучасних вікнах ПВХ одна з головних переваг – можливість самостійного регулювання більшості елементів фурнітури. Просте регулювання вікон доступне кожному, просто потрібно пробувати і все вийде.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreen />
        </div>
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;