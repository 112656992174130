import React from "react";
import ImgLogo from '../img/windows.png';
import './HeaderLogo.css';
import { Link } from 'react-router-dom';

function Logo () {
    return (
        <div className="logo" ><Link to="/">
            <img  src={ImgLogo} alt="logo"/>
            <h3>Spravni Vikna</h3>
            </Link>
        </div>
    );
}

export default Logo;