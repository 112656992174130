import React from 'react';
import './TelSubmit.css';
import Lang from './Lang';
import Phone2 from '../img/phone3.png';
import { Link } from 'react-router-dom';
import { useChange } from '../../App';
import photoInstaram from '../img/instagram.png'; 

function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
    return (
        <div className="tel">
            <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
        </div>
          );
}

function LangTel () {
const {isScroll, setScroll} = useChange();

    window.addEventListener('scroll', () => {
        let scrolled = window.pageYOffset;
        if (scrolled > 150) {
            setScroll(true);
        }
        if (scrolled < 150) {
            setScroll(false);
        }
    })

    return (
        <div className={isScroll ? 'ContainerFixed' : 'none'}>
        <div className={isScroll ? "fixedlangTel": 'langTel' }>
            <Instargam />
            <Tel />
            <Lang />
        </div>
        </div>
    );
}

export default LangTel;