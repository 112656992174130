import furnit from './../../img/forsite/Articels/furnit.jpg';
import furnit2 from './../../img/forsite/Articels/furnit2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, { useState } from "react";
import FirstScreenRu from "../../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../../FiveScreenRu/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articeltwo'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }


function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Ремонт фурнитуры</h2>
            <img className="quilon" src={furnit} title='фурнітура' alt="фурнітура"/>
            <p>
            Фурнитура – ​​это набор элементов окна, отвечающих за открывание и закрывание створки окна. Она обеспечивает герметичность закрытого окна. Правильно подобранная оконная фурнитура является одной из важнейших составляющих качественного окна и зависит от длительного срока эксплуатации пластиковой конструкции.
            </p>
            <p>
            Фурнитура состоит из множества элементов, основными являются:
            </p>
            <ul className="furnitul">
                <li>Петля – это часть фурнитуры, которая служит для соединения створки и рамы окна. Они бывают верхними и нижними (имеют такое название за счет своего расположения на створке).</li>
                <li>Запорный механизм обеспечивает надежное закрывание.</li>
                <li>Угловые переключатели – гибкие стальные пластины, предназначенные для закрытия окна через угол.</li>
                <li>Ручка – элемент окна, с помощью которого открывается и закрывается створка.</li>
                <li>Рычаги – приводят створку в подвижность, или фиксируют в определенном положении.</li>
                <li>Защитные элементы – обеспечивают безопасность в эксплуатации окна. Это блокаторы поворота, ограничители и другие механизмы.</li>
            </ul>
         
            <p>
            Ремонт фурнитуры производится когда одна из ее частей не функционирует, это может быть угловая передача, средний привод, основанием привод, ножницы или нижнее окончание. Как правило, при диагностике выявляется какая именно часть или части не работают, после чего производится замена или ее ремонт.
            </p>
            <p>
            При больших нагрузках на фурнитере бывает отламывающийся штифт оконной ручки, после чего нельзя делать никаких действий такой ручкой. Нужно или зажать штифт или заменить ручку.
            </p>
            <p>
           
            </p>
            <p>
            В основном при правильной диагностике изломанной фурнитуры решается и ее проблема с минимальными затратами для клиента.
            </p>
            <img className="furnit2" src={furnit2} title='фурнітура' alt="фурнітура"/>
            <p>
            Главное не забывать, что фурнитура, как и любой другой механизм, нуждается в постоянном уходе. Для этого необходимо периодически смазывать маслом (без содержания смолы и кислоты) и осматривать все подвижные элементы на наличие дефектов. Такие процедуры будут продолжать работу вашей фурнитуры.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Furnitura />
        <FiveScreenRu />
        </div>
    );
}
export default ArticelTwo;