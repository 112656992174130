import regul from './../../img/forsite/Articels/article9.jpg';
import regul2 from './../../img/forsite/Articels/article10.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import FirstScreenRu from "../../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../../FiveScreenRu/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelfive'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }
function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Регулировка окон</h2>
            <img className="quilon" src={regul} title='фурнітура' alt="фурнітура"/>
            <p>
            Для регулировки пластиковых окон необходимо понимать, что регулировке подвергается не самое окно, а его внутренний механизм, так называемая фурнитура. Это очень важный элемент оконной конструкции. Хорошее функционирование системы влияет на надежную и стабильную работу всего окна. Основная задача поворотно-откидного механизма, быть надежным инструментом при открывании и закрывании створки окна или двери, с помощью ручки, расположенной на вертикальной части.
            </p>
            <p>
            Для хорошей и надежной работы фурнитуры ее необходимо чистить и смазывать хотя бы раз в год. При несоблюдении этих правил эксплуатации впоследствии возможна поломка всего механизма. Так же возможно проседание створки из-за значительного веса, а это ведет к появлению зазоров в верхней части окна и изменению всей геометрии.
            </p>
            <img className="regul" src={regul2} title='фурнітура' alt="фурнітура"/>
            <p>
            В настоящее время современная фурнитура позволяет наклонять и поднимать створку, так же дает возможность передвигать ее в горизонтальной плоскости петлевой группы, что способствует усилению общего прижима по всему периметру окна. Чтобы самостоятельно регулировать прижим и ход механизма фурнитуры предусматриваются регулировочные винты.
            </p>
            <p>
            По стандарту оконные петли действуют в двух положениях регулировки. Возможны следующие режимы регулировки: горизонтальная, продольная (позволяет перемещать створку вправо и влево); вертикальная (позволяет перемещаться вниз и вверх). При необходимости регулируется и прижим створки, который обеспечивается за счет цапф в ответ и планок скольжения. Данная регулировка необходима по мере износа уплотнительного контура (резиновый уплотнитель) в тот момент, когда он теряет свои качественные свойства (потеря гибкости, рыхлость).
            </p>
            <p>
            Главное следует помнить, что бережное отношение к фурнитуре продлит ее работу на долгие годы.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Furnitura />
        <FiveScreenRu />
        </div>
    );
}
export default ArticelTwo;