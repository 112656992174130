import FirstScreenRu from "../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../FiveScreenRu/FiveScreen';
import '../Pages/Services.css';
import Phone2 from '../img/phone3.png';
import React, { useState } from "react";
import '../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../App';
import photoInstaram from '../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/services'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
        return (
            <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
            <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
            </Link>
        )
    }
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }


export function Price() {
    return (
        <>
        <div className="price">
            <h2 className="other">Цены на ремонт окон</h2>
        <div className="outsidewidthtable">
        <table className="table">
                <tbody> 
            <tr>
            <th className="thleft" >Наименование услуг</th>
            <th >Стоимость (грн)</th>
            </tr>

        <tr>
                <td>Ремонт, регулировка створки</td>
                <td className="center">от {250}</td>
        </tr>
        <tr>
                    
                <td>Ремонт, регулировка балконной дверки</td>
                <td className="center">от {300}</td>
                    
        </tr>
        <tr>
                <td>Ремонт, регулировка распашных систем</td>
                <td className="center">от {400}</td>
        </tr>

        <tr>
                <td>Замена однокамерного стеклопакета</td>
                <td className="center">от {2700} за 1 кв.м.</td>
        </tr>
        <tr>
                <td>Замена двокамерного стеклопакета</td>
                <td className="center">от {3700} за 1 кв.м.</td>
        </tr>
        <tr>
                <td>Выравнивание геометрии створки</td>
                <td className="center">от {350}</td>
        </tr>
        <tr>
                <td>Частичная замена фурнитуры</td>
                <td className="center">от {750}</td>
        </tr>
        <tr>
                <td>Замена основного привода фурнитуры</td>
                <td className="center">от {850}</td>
        </tr>
        <tr>
                <td>Замена ножниц</td>
                <td className="center">от {950}</td>
        </tr>
        <tr>
                <td>Замена угловой передачи</td>
                <td className="center">от {450}</td>
        </tr>
        <tr>
                <td>Замена среднего привода</td>
                <td className="center">от {650}</td>
        </tr>
        <tr>
                <td>Замена поворотной фурнитуры </td>
                <td className="center">от {1700}</td>
        </tr>
        
        <tr>
                <td>Замена поворотно-откидной фурнитуры Maco </td>
                <td className="center">от {2700}</td>
        </tr>
        <tr>
                <td>
                Переделка поворотной створки на поворотно-откидную
                </td>
                <td className="center">от {2700}</td>
        </tr>
        <tr>
                <td>Установка микропроветривания</td>
                <td className="center">от {500}</td>
        </tr>
        <tr>
                <td>Замена ответной планки</td>
                <td className="center">от {80}</td>
        </tr>
        <tr>
                <td>Замена уплотнителя (Q-lon)</td>
                <td className="center"> от {130} за 1 п.м.</td>
        </tr>
        <tr>
                <td>Замена резинового уплотнителя</td>
                <td className="center">от {80} за 1 п.м.</td>
        </tr>
        <tr>
                <td>Замена петли створки</td>
                <td className="center">от {950}</td>
        </tr>
        <tr>
                <td>Замена ручки</td>
                <td className="center">от {280}</td>
        </tr>
        <tr>
                <td>Установка противзломних систем</td>
                <td className="center">от {350}</td>
        </tr>
        <tr>
                <td>Установка ограничителя</td>
                <td className="center">от {350}</td>
        </tr>

        <tr>
                <td>Установка ручки курильщика</td>
                <td className="center">от {100}</td>
        </tr>
        <tr>
                <td>Установка защелки для балконной двери</td>
                <td className="center">от {270}</td>
        </tr>
        <tr>
                <td>Установка внешних прижимов</td>
                <td className="center">от {300}</td>
        </tr>
        <tr>
                <td>Внутренняя герметизация</td>
                <td className="center">от {100} за 1 м.п.</td>
        </tr>
        <tr>
                <td>Минимальный выезд</td>
                <td className="center">от {500}</td>
        </tr>
        <tr>
                <td>Дополнительный выезд</td>
                <td className="center">от {300}</td>
        </tr>
        <tr>
                <td>Дополнительные услуги</td>
                <td className="center">Зависит от сложности работ</td>
        </tr>
        </tbody>
        </table>
        </div>
            </div>
        </>
    );
}

function Services() {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Price />
        <FiveScreenRu />
       
        </div>
      
    );
}

export default Services;
