import React from "react";
import { useLocation } from "react-router-dom";
import './Services.css';
import { Link } from "react-router-dom";

function Whoops404() {
    let location = useLocation();
    console.log(location);
    return (
        <div className="error404">
            <h1 className="nofindresurs">
                Warning! Ресурс не знайдено...
            </h1>
            <p>Перейдіть будь ласка на <span> <Link to="/">головну сторінку</Link></span></p>
        </div>
    );
}
export default Whoops404;