import windows from '../../img/forsite/Articels/article7.jpg';
import window2 from '../../img/forsite/Articels/article8.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import FirstScreenRu from "../../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../../FiveScreenRu/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelfour'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Зачем нужно расклинивать створку?</h2>
            <img className="quilon" src={windows} title='window' alt="window"/>
            <p>
            Нередки случаи, когда металлопластиковое окно, радовавшее своей безотказной работой в течение довольно продолжительного времени начинает давать сбои. Эти сбои могут проявляться в виде заедания створки о раме при открывании и закрывании, нечеткой работе фурнитуры, или вообще невозможности открыть или закрыть окно. Если с помощью регулировок фурнитуры, например петель, эту проблему не удается устранить, существует почти стопроцентная вероятность того, что стеклопакет был неправильно расклеен на производстве.
            </p>
            <p>Чтобы расстегнуть створку, необходим прежде всего специальный инструмент. Для снятия штапиков – это металлический шпатель, широкий нож или отвертка. Для регулировки пакета требуется специальная лопатка для стеклопакетов. И для монтажа штапиков специальный молоток.</p>
           
            <img className="window" src={window2} title='window' alt="window"/>
            <p>
            Порядок расклинивания створки:
            </p>
            <ul className="windowraz">
                <li>Снимаем штапки. Сначала нужно снимать больше штапиков, это как правило левый или правый штапик. Отжимаем штапик от профиля, затем расширяем эту щель до краев. В результате штапик «выходит» из зажимов-фиксаторов, и его можно снять. Ставьте метки на штапике для правильного монтажа.</li>
                <li>Снимаем стеклопакет и выставляем подкладки. В неких вариантах можно не снимать стеклопакет, а просто подправить дистанционные подложки.</li>
                <li>Закончив с выставлением подложек, устанавливаем штапики на место в обратном порядке.</li>
            </ul>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Furnitura />
        <FiveScreenRu />
        </div>
    );
}
export default ArticelTwo;