import React, { useEffect, useState } from "react";
import './LinkQuick.css';
import { Link } from "react-router-dom";

function LinkQuick () {
    const [isScrollUp, setScrollUp] = useState(true);
    
useEffect(() => {
    if(isScrollUp){
     window.scroll({top: 0});
     setScrollUp(false);
    }
 }, [isScrollUp]);

    return (
        <div className="linkQuick">
            <ul>
            <li onClick={() => setScrollUp(true)} ><Link to="/rus">Главная</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to='/rus/services'>Услуги</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to="/rus/gallary">Галерея</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to="/rus/contacts">Контакты</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to="/rus/articelone">Все об окнах</Link></li>
            </ul>
         </div>
    );
}

export default LinkQuick;