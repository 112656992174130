import furnit from './../../img/forsite/Articels/furnit.jpg';
import furnit2 from './../../img/forsite/Articels/furnit2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, { useState } from "react";
import FirstScreen from "../../FirstScreen/ContainerOne";
import FiveScreen from '../../FiveScreen/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>UA</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/rus/articeltwo'>RU</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam/>
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }



function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Ремонт фурнітури</h2>
            <img className="quilon" src={furnit} title='фурнітура' alt="фурнітура"/>
            <p>
            Фурнітура – ​​це набір елементів вікна, які відповідають за відкривання та закривання стулки вікна. Вона забезпечує герметичність закритого вікна. Правильно підібрана віконна фурнітура є однією з найважливіших складових якісного вікна і залежить від тривалий термін експлуатації пластикової конструкції.
            </p>
            <p>
            Фурнітура складається з безлічі елементів, основними є:
            </p>
            <ul className="furnitul">
                <li>Петля - це частина фурнітури, яка служить для з'єднання стулки і рами вікна. Вони бувають верхніми та нижніми (мають таку назву за рахунок свого розташування на стулці).</li>
                <li>Запірний механізм забезпечує надійне закривання.</li>
                <li>Кутові перемикачі – гнучкі сталеві пластини, призначені для закриття вікна через кут.</li>
                <li>Ручка – елемент вікна, за допомогою якого відбувається відкривання та закривання стулки.</li>
                <li>Важелі - приводять стулку в рухливість, або фіксують у певному положенні.</li>
                <li>Захисні елементи – надають безпеку в експлуатації вікна. Це блокатори повороту, обмежувачі та інші механізми.</li>
            </ul>
         
            <p>
            Ремонт фурнітури проводиться коли одна з її частин не функціонує, це може бути кутова передача, привід середній, основою привід, ножиці або нижнє закінчення. Як правило, при діагностиці виявляється яка саме частина або частини не працюють, після чого проводиться заміна або її ремонт.
            </p>
            <p>
            При великих навантаженнях на фурнітері буває відламується штифт віконної ручки, після чого не можна робити ніяких дій такою ручкою. Потрібно або затиснути штифт або замінити ручку
            </p>
    
            <p>
            В основному при правильній діагностиці поламаної фурнітури вирішується і її проблема з мінімальними витратами для клієнта.
            </p>
            <img className="furnit2" src={furnit2} title='фурнітура' alt="фурнітура"/>
            <p>
            Головне не забувати, що фурнітура, як і будь-який інший механізм, потребує постійного догляду. Для цього її необхідно періодично змащувати олією (без вмісту смоли та кислоти) та оглядати всі рухливі елементи на наявність дефектів. Такі процедури продовжуватимуть роботу вашої фурнітури.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreen />
        </div>
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;