import quilon from './../../img/forsite/Articels/foto9.jpg';
import quilon2 from './../../img/forsite/Articels/qlon.png';
import './../Articels/Articeles.css';

import React, { useEffect, useState } from "react";
import FirstScreenRu from "../../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../../FiveScreenRu/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link, NavLink } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelone'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }



export function Cataloge () {
const [isScroll, setScroll] = useState(true);

useEffect(() => {
   if(isScroll){
    window.scroll({top: -100, behavior: 'smooth'});
    setScroll(false);
   }
}, [isScroll])

    return (
    <div className="catalogs">
        <h3>Каталог</h3>
        <ul>
            <li  onClick={() => setScroll(true)}><NavLink to="/rus/articelone">Замена уплотнителя</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/rus/articeltwo">Ремонт фурнитуры</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/rus/articelthree">Замена стеклопакета</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/rus/articelfour">Расклинивание створки</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/rus/articelfive">Регулировка окон</NavLink></li>
            <li ><NavLink to="/rus/articelsix"  onClick={() => setScroll(true)}>Инструменты</NavLink></li>
        </ul>
    </div>
    );
}

function Quilon() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Замена уплотнителя</h2>
            <p> 
            Как правило, причиной замены уплотнительной резины является потеря смазки (вязкости), из-за ее неправильной эксплуатации или долгим сроком эксплуатации. В результате чего уплотнитель затвердевает и не выполняет полностью своей функции. Из окон начинает дуть.
            </p>
            <img className="quilon" src={quilon} title='quilon' alt="quilon"/>
            <p>
            Уплотнитель бывает резиновый или полиуретановый губчатый. Резиновый лотник нужно смазывать силиконовым спреем 1 или 2 раза в год. Преимущество полиуретанового губчатого уплотнителя Q-Lon Schlegel состоит в том, что его не нужно систематически смазывать силиконовым спреем. Экологически чистый – без фриосодержащих веществ. Немецкий уплотнитель Q-Lon Schlegel необходимо уметь правильно устанавливать.
            </p>
            <img className="quilon2" src={quilon2} title='quilon' alt="quilon"/>
            <p>
            Предлагаем качественную замену изношенного, порванного или неправильно подобранного уплотнителя на губчатый полиуретановый уплотнитель Q-Lon Schlegel (Германия) или резиновый уплотнитель под профиль Rehau, Salamander.
            </p>
        </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}

function ArticelOne () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Quilon />
        <FiveScreenRu />
        </div>
    );
}
export default ArticelOne;