import React, { createContext, useState, useContext } from 'react';
import './index.css';
import './App.css';
import FirstScreen from './components/FirstScreen/FirstScreen';
import SecondScreen from './components/SecondSreen/SecondScreen';
import ThirdScreen from './components/ThirdScreen/ThirdScreen';
import FourScreen from './components/FourScreen/FourScreen';
import FiveScreen from './components/FiveScreen/FiveScreen';
import { Routes, Route } from 'react-router-dom';
import Services from './components/Pages/Services';
import Whoops404 from './components/Pages/Whoops404';
import Gallary from './components/Pages/Gallary';
import Contacts from './components/Pages/Contacts';
import ArticelOne from './components/Pages/Articels/ArticelOne';
import ArticelTwo from './components/Pages/Articels/ArticelTwo';
import ArticelThree from './components/Pages/Articels/ArticelThree';
import ArticelFour from './components/Pages/Articels/ArticelFour';
import ArticelFive from './components/Pages/Articels/ArticelFive';
import ArticelSix from './components/Pages/Articels/ArticelSix';
import FirstScreenRu from './rus/FirstScreenRu/FirstScreen';
import SecondScreenRu from './rus/SecondSreenRu/SecondScreen';
import ThirdScreenRu from './rus/ThirdScreenRu/ThirdScreen';
import FourScreenRu from './rus/FourScreenRu/FourScreen';
import FiveScreenRu from './rus/FiveScreenRu/FiveScreen';
import ServicesRu from './rus/Pages/Services';
import GallaryRu from './rus/Pages/Gallary';
import ContactsRu from './rus/Pages/Contacts';
import ArticelOneRu from './rus/Pages/Articels/ArticelOne';
import ArticelTwoRu from './rus/Pages/Articels/ArticelTwo';
import ArticelThreeRu from './rus/Pages/Articels/ArticelThree';
import ArticelFourRu from './rus/Pages/Articels/ArticelFour';
import ArticelFiveRu from './rus/Pages/Articels/ArticelFive';
import ArticelSixRu from './rus/Pages/Articels/ArticelSix';

export const ChangeContext = createContext();

// hook useContext
export const useChange = () => useContext(ChangeContext);


export function Main() {

  return (
    <div className="body_wrapper">
      <FirstScreen />
      <SecondScreen />
      <ThirdScreen />
      <FourScreen />
      <FiveScreen />
    </div>
  );
}

export function MainRu() {
  return (
    <div className="body_wrapper">
    <FirstScreenRu />
    <SecondScreenRu />
    <ThirdScreenRu />
    <FourScreenRu />
    <FiveScreenRu />
  </div>
  )
}

function App () {
  const [isScroll, setScroll] = useState(false);

  return (
    <ChangeContext.Provider value={{isScroll, setScroll}}>
      <Routes> 
        <Route path='/' element={<Main/>} />
        <Route path='/services' element={<Services />} />
        <Route path='/gallary' element={<Gallary />} />
        <Route path='/contacts' element={<Contacts/>} />
        <Route path='/articelone' element={<ArticelOne/>} />
        <Route path='/articeltwo' element={<ArticelTwo/>} />
        <Route path='/articelthree' element={<ArticelThree/>} />
        <Route path='/articelfour' element={<ArticelFour/>} />
        <Route path='/articelfive' element={<ArticelFive/>} />
        <Route path='/articelsix' element={<ArticelSix/>} />
        <Route path='*' element={<Whoops404 />} />
        <Route path='/rus' element={<MainRu/>} />
        <Route path='/rus/services' element={<ServicesRu/>} />
        <Route path='/rus/gallary' element={<GallaryRu/>} />
        <Route path='/rus/contacts' element={<ContactsRu/>} />
        <Route path='/rus/articelone' element={<ArticelOneRu/>} />
        <Route path='/rus/articeltwo' element={<ArticelTwoRu/>} />
        <Route path='/rus/articelthree' element={<ArticelThreeRu/>} />
        <Route path='/rus/articelfour' element={<ArticelFourRu/>} />
        <Route path='/rus/articelfive' element={<ArticelFiveRu/>} />
        <Route path='/rus/articelsix' element={<ArticelSixRu/>} />
      </Routes>
     </ChangeContext.Provider>
  );
}

export default App;