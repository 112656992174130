import TelegramImg from '../img/teleg.png';
import Viber from '../img/viber.png';

import FirstScreenRu from "../FirstScreenRu/ContainerOne";
import FiveSCreenRu from '../FiveScreenRu/FiveScreen';
import '../Pages/Services.css';
import Phone2 from '../img/phone3.png';
import React, { useState } from "react";
import '../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../App';
import photoInstaram from '../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/contacts'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }


function Contacts() {
    return (
        <> 
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
<div className="mainContacts">
        <h2>О Компании</h2>
        <p>Компания "Spravni Vikna" – это надежная компания для ремонта и обслуживания окон. На рынке Украины работаем с 2015 года. Нам нравится делать то, чем мы занимаемся и это наше хорошее качество. Всегда стараемся объяснить нашим клиентам причины поломки и как можно решить их максимально просто и недорого. Девиз нашей компании, исправные окна – это хорошее настроение, тепло и уют в доме». Мы предоставляем широкий спектр работ по ремонту и обслуживанию металлопластиковых окон и дверей. Работаем в Киеве.</p>
    <div >
            <h2 className="othercontacts">Контакты</h2>
<div className="contacts">
    <div className="wrapcontacts"> 
    <Link to="tel:+38(063)4695007" className="link phone" title="telegram">Номер телефона <span className="phonebotton">(063) 469 50 07</span></Link>
   
    <p className="link">С нами можно связаться через вайбер или телеграмм:</p>
    <div className="vibertelegram" >
    <Link to="viber://chat?number=%2B380634695007"  className="link viber"> <img title="Viber" src={Viber} alt="viber"/></Link>
    <Link to="tg://resolve?domain=Ok_Service23"  className="link telegram"> <img src={TelegramImg} title="Telegram" alt="telegram"/></Link>
    </div>
    <p className="link">Или написать нам сообщение по электронному адресу:</p>
    <Link to="mailto:spravnivikna@gmail.com"  className="link mail"><span>spravnivikna@gmail.com</span></Link> 
    </div>
</div>

</div>
</div>

        <FiveSCreenRu />
        </div>
        </>
       
    );
}
export default Contacts;