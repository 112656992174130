import instrum from './../../img/forsite/Articels/article5.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import FirstScreenRu from "../../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../../FiveScreenRu/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelsix'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Инструменты для ремонта окон</h2>
            <img className="quilon" src={instrum} title='фурнітура' alt="фурнітура"/>
            <p>
            Качественная регулировка возможна при использовании соответствующих инструментов. Необходимо подготовить следующий набор:
            </p>
            <ul className="furnitul">
                <li>Ключ-шестигранник на 4 мм – основной инструмент в настройке. Форма огромного значения не имеет, традиционно употребляются Г-образные ключи. Существуют и профессиональные ключи, с изгибом для более удобной работы.</li>
                <li>Звездообразные насадки на отвертке. Данные инструменты маркируются обозначениями Т или ТХ, которые не всегда используются. Они могут пригодиться только для отдельных типов оконной фурнитуры.</li>
                <li>Специальная ручка для окон.</li>
                <li>Крестовая отвертка размера 3 или 4. Чаще фурнитура крепится на окне болтами, откручивающимися стандартной крестовой отверткой. Такой инструмент пригодится при замене или регулировке ручки.</li>
                <li>Плоскогубцы.</li>
                <li>Подходящая для фурнитуры смазка (BL-40 или WD-40).</li>
                <li>Шуруповерт.</li>
                <li>Сверла разного диаметра.</li>
            </ul>
            <p>
            В современных окнах ПВХ одно из главных преимуществ – возможность самостоятельной регулировки большинства элементов фурнитуры. Простая регулировка окон доступна каждому, просто нужно пробовать и все получится.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Furnitura />
        <FiveScreenRu />
        </div>
    );
}
export default ArticelTwo;