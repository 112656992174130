import React from "react";
import Phone2 from './../img/phone2.png';
import './Contact.css';
import { Link } from "react-router-dom";

function FooterH34 () {
    return (
        <div className="footerH34">
            <h4>Spravni Vikna</h4>
            <h3>Ремонт окон и дверей</h3>
        </div>
    );
}
function TelFooter () {
    return (
        <div className="telFooter">
             <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
        </div>
    );
}
function Contact () {
    return (
        <div className="contact">
            <FooterH34 />
            <TelFooter />
         </div>
    );
}

export default Contact;