import FirstScreen from "../FirstScreen/ContainerOne";
import FiveScreen from '../FiveScreen/FiveScreen';
import '../Pages/Services.css';
import Phone2 from '../img/phone3.png';
import React, { useState } from "react";
import '../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../App';
import photoInstaram from '../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>UA</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/rus/services'>RU</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
        return (
            <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
            <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
            </Link>
        )
    }
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
            <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }


export function Price() {
    return (
        <>
        <div className="price">
            <h2 className="other">Ціни на ремонт вікон</h2>
        <div className="outsidewidthtable">
        <table className="table">
                <tbody> 
            <tr>
            <th className="thleft" >Найменування послуг</th>
            <th >Вартість (грн)</th>
            </tr>

        <tr>
                <td>Ремонт, регулювання стулки</td>
                <td className="center">від {250}</td>
        </tr>
        <tr>
                    
                <td>Ремонт, регулювання балконної дверки</td>
                <td className="center">від {300}</td>
                    
        </tr>
        <tr>
                <td>Ремонт, регулювання розпашних систем</td>
                <td className="center">від {400}</td>
        </tr>
        <tr>
                <td>Заміна однокамерного склопакета</td>
                <td className="center">від {2700} за 1 кв.м.</td>
        </tr>
        <tr>
                <td>Заміна двокамерного склопакета</td>
                <td className="center">від {3700} за 1 кв.м.</td>
        </tr>

        <tr>
                <td>Вирівнювання геометрії стулки</td>
                <td className="center">від {350}</td>
        </tr>
        <tr>
                <td>Часткова заміна фурнітури</td>
                <td className="center">від {750}</td>
        </tr>
        <tr>
                <td>Заміна основного приводу фурнітури</td>
                <td className="center">від {850}</td>
        </tr>
        <tr>
                <td>Заміна ножиць</td>
                <td className="center">від {950}</td>
        </tr>
        <tr>
                <td>Заміна кутової передачі</td>
                <td className="center">від {450}</td>
        </tr>
        <tr>
                <td>Заміна середнього привода</td>
                <td className="center">від {650}</td>
        </tr>
        
        <tr>
                <td>Заміна поворотної фурнітури </td>
                <td className="center">від {1700}</td>
        </tr>
        
        <tr>
                <td>Заміна поворотно-відкидної фурнітури Maco </td>
                <td className="center">від {2700}</td>
        </tr>
        <tr>
                <td>
                Перероблення повортної стулки на поворотно-відкидну
                </td>
                <td className="center">від {2700}</td>
        </tr>
        <tr>
                <td>Установка мікропровітрювання</td>
                <td className="center">від {500}</td>
        </tr>
        <tr>
                <td>Заміна відповідної планки</td>
                <td className="center">від {80}</td>
        </tr>
        <tr>
                <td>Заміна ущільнювача (Q-lon)</td>
                <td className="center"> від {130} за 1 п.м.</td>
        </tr>
        <tr>
                <td>Заміна гумового ущільнювача</td>
                <td className="center">від {80} за 1 п.м.</td>
        </tr>
        <tr>
                <td>Заміна петлі стулки</td>
                <td className="center">від {950}</td>
        </tr>
        <tr>
                <td>Заміна ручки</td>
                <td className="center">від {280}</td>
        </tr>
        <tr>
                <td>Установка противзломних систем</td>
                <td className="center">від {350}</td>
        </tr>
        <tr>
                <td>Установка обмежувача</td>
                <td className="center">від {350}</td>
        </tr>

        <tr>
                <td>Установка ручки курця</td>
                <td className="center">від {100}</td>
        </tr>
        <tr>
                <td>Установка засувки для балконної дверки</td>
                <td className="center">від {250}</td>
        </tr>
        <tr>
                <td>Установка зовнішниіх прижимів</td>
                <td className="center">від {300}</td>
        </tr>
        <tr>
                <td>Внутрішня герметизація</td>
                <td className="center">від {100} за 1 м.п.</td>
        </tr>
        <tr>
                <td>Мінімальний виїзд</td>
                <td className="center">від {500}</td>
        </tr>
        <tr>
                <td>Додатковий виїзд</td>
                <td className="center">від {300}</td>
        </tr>
        <tr>
                <td>Додаткові послуги</td>
                <td className="center">Залежить від важкості робіт</td>
        </tr>
        </tbody>
        </table>
        </div>
            </div>
        </>
    );
}

function Services() {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreen />
        </div>
        <Price />
        <FiveScreen />
       
        </div>
      
    );
}

export default Services;
