import quilon from './../../img/forsite/Articels/foto9.jpg';
import quilon2 from './../../img/forsite/Articels/qlon.png';
import './../Articels/Articeles.css';

import React, { useEffect, useState } from "react";
import FirstScreen from "../../FirstScreen/ContainerOne";
import FiveScreen from '../../FiveScreen/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link, NavLink } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>UA</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/rus/articelone'>RU</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }

export function Cataloge () {
const [isScroll, setScroll] = useState(true);

useEffect(() => {
   if(isScroll){
    window.scroll({top: -100, behavior: 'smooth'});
    setScroll(false);
   }
}, [isScroll])

    return (
    <div className="catalogs">
        <h3>Каталог</h3>
        <ul>
            <li  onClick={() => setScroll(true)}><NavLink to="/articelone">Заміна ущільнювача</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/articeltwo">Ремонт фурнітури</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/articelthree">Заміна склопакету</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/articelfour">Розклинювання стулки</NavLink></li>
            <li  onClick={() => setScroll(true)}><NavLink to="/articelfive">Регулювання вікон</NavLink></li>
            <li ><NavLink to="/articelsix"  onClick={() => setScroll(true)}>Інструменти</NavLink></li>
        </ul>
    </div>
    );
}

function Quilon() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Заміна ущільнювача</h2>
            <p> 
                Як правило, причиною заміни ущільнювальної гуми є втрата мастила (в'язкості), через її неправильну експлуатацію або довгим строком екслуатації. Внаслідок чого ущільнювач твердне і не виконує повністю своєї функції. З вікон починає дмухати.
            </p>
            <img className="quilon" src={quilon} title='quilon' alt="quilon"/>
            <p>
            Ущільнювач буває гумовий чи поліуретановий губчастий. Гумовий улотник потрібно змащувати силіконовим спреєм 1 або 2 рази на рік. Перевага поліуретанового губчастого ущільнювача Q-Lon Schlegel у тому, що його не потрібно систематично змащувати силіконовим спреєм. Екологічно чистий - без фріоновмісних речовин. Німецький ущільнювач Q-Lon Schlegel потрібно вміти правильно встановлювати.
            </p>
            <img className="quilon2" src={quilon2} title='quilon' alt="quilon"/>
            <p>
            Пропонуємо якісну заміну зношеного, порваного або неправильно підібраного ущільнювача на губчастий поліуретановий ущільнювач Q-Lon Schlegel (Німеччина) або гумовий ущільнювач під профіль Rehau, Salamander.
            </p>
        </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}

function ArticelOne () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreen />
        </div>
        <Quilon />
        <FiveScreen />
        </div>
    );
}
export default ArticelOne;