import React from "react";
import Header from "./Header";


function ContainerOne () {
    return (
        <>
        <div className="containerOne"> 
        <Header />
        </div>
        </>
    )
}

export default ContainerOne;