import React from "react";
import Choose from "./Choose";
import './ContainerElements.css';
import { Link } from "react-router-dom";

function ContainerH2H3 () {
    return (
        <>
            <h3>Наши услуги и их стоимость</h3>
            <h1>Регулировка и ремонт металлопластиковых окон и дверей</h1>
        </>  
    );
}
function Check () {
    return (
        <div className="check">
            <li><Link to='/rus/services'>Подробнее...</Link></li>
        </div>
    );
}
function ContainerElements () {
    return (
        <div className="container">
            <ContainerH2H3 />
            <Choose />
            <Check />
        </div>
    );
}


export default ContainerElements;