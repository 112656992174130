import React from "react";
import Choose from "./Choose";
import './ContainerElements.css';
import { Link } from "react-router-dom";

function ContainerH2H3 () {
    return (
        <>
            <h3>Наші послуги та їх вартість</h3>
            <h1>Регулювання та ремонт металопластикових вікон та дверей</h1>
        </>  
    );
}
function Check () {
    return (
        <div className="check">
            <li><Link to='/services'>Детальніше...</Link></li>
        </div>
    );
}
function ContainerElements () {
    return (
        <div className="container">
            <ContainerH2H3 />
            <Choose />
            <Check />
        </div>
    );
}


export default ContainerElements;