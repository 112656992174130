import React, { useEffect, useState } from "react";
import './LinkQuick.css';
import { Link } from "react-router-dom";

function LinkQuick () {
    const [isScrollUp, setScrollUp] = useState(true);
    
useEffect(() => {
    if(isScrollUp){
     window.scroll({top: 0});
     setScrollUp(false);
    }
 }, [isScrollUp]);

    return (
        <div className="linkQuick">
            <ul>
            <li onClick={() => setScrollUp(true)} ><Link to="/">Головна</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to='/services'>Послуги</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to="/gallary">Галерея</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to="/contacts">Контакти</Link></li>
            <li onClick={() => setScrollUp(true)}><Link to="/articelone">Все про вікна</Link></li>
            </ul>
         </div>
    );
}

export default LinkQuick;