import React from "react";
import ContainerOne from "./ContainerOne";
import "./FirstScreen.css";
import LangTel from "./TelSubmit";

function FirstScreen () {
    return (
        <div className="first_screen">
            <LangTel/>
           <ContainerOne />
        </div>
    );
}

export default FirstScreen;