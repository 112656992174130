import windows from './../../img/forsite/Articels/article7.jpg';
import window2 from './../../img/forsite/Articels/article8.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, { useState } from "react";
import FirstScreen from "../../FirstScreen/ContainerOne";
import FiveScreen from '../../FiveScreen/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>UA</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/rus/articelfour'>RU</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam/>
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Навіщо потрібно розклинювати стулку?</h2>
            <img className="quilon" src={windows} title='window' alt="window"/>
            <p>
            Непоодинокі випадки, коли металопластикове вікно, що тішило своєю безвідмовною роботою протягом досить тривалого часу починає давати збої. Ці збої можуть виявлятися у вигляді заїдання стулки про раму при відкриванні та закриванні, нечіткій роботі фурнітури, або, взагалі, у неможливості відкрити або закрити вікно. Якщо за допомогою регулювань фурнітури, наприклад петель, цю проблему не вдається усунути, існує майже стовідсоткова ймовірність того, що склопакет був неправильно розклинений на виробництві.
            </p>
            <p>Щоб розсклинити стулку потрібен насамперед спеціальний інструмент. Для зняття штапиків – це металевий шпатель, широкий ніж або викрутку. Для регулювання пакета потрібна спеціальна лопатка для склопакетів. І для монтажу штапиків спеціальний молоток.</p>
           
            <img className="window" src={window2} title='window' alt="window"/>
            <p>
            Порядок розклинювання стулки:
            </p>
            <ul className="windowraz">
                <li>Знімаємо штапки. Спочатку потрібно знімати більше штапики, це як правило лівий або правий штапик. Віджимаємо штапик від профілю, а потім розширюємо цю щілину до країв. В результаті штапик «виходить» із затискачів-фіксаторів, і його можна зняти. Ставте помітки на штапику для правильного монтажу.</li>
                <li>Знімаємо склопакет та виставляємо підкладки. У деяких випадках можна не знімати склопакет, а просто підправити дистанційні підкладки.</li>
                <li>Закінчивши з виставленням підкладок, встановлюємо штапики на місце у зворотному порядку.</li>
            </ul>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreen />
        </div>
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;