import React from "react";
import telegram from './../img/telegram94.png';
import { Link } from 'react-router-dom';

function Footer () {
    return (
        <>
        <div className="telegramtitle">
        <Link to="tg://resolve?domain=Ok_Service23"  className="link telegram"> <img src={telegram} title="Telegram" alt="telegram"/></Link>
        </div>
        <div className="footer">
            <span> Spravni Vikna | 2015 - 2023</span>
        </div>
        </>
    );
}

export default Footer;