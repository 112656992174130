import React, { useState } from "react";
import './BurgerMenu.css';
import BackDropLogin from "./HeaderLogin";
import { Link } from "react-router-dom";


function FullMenu () {
    return (
        <>
        <ul id="fullMenu">
            <li><Link to="/rus">Главная</Link></li>
            <li><Link to='/rus/services'>Услуги</Link></li>
            <li><Link to="/rus/gallary">Галерея</Link></li>
            <li><Link to="/rus/contacts">Контакты</Link></li>
            <li><Link to="/rus/articelone">Все об окнах</Link></li>
        </ul>
        </>
    );
}
function NavWrap () {
    const [isHidden, setHidden] = useState(false);
    function onState () {
        if (isHidden) {
            setHidden(false);          
        }else {
            setHidden(true);
        }
    }
    // console.log(isHidden);
    return (
        <>
        {isHidden && (<div onClick={() => setHidden(false)} className={isHidden ? 'back-dropShortMenu' : ''}> </div>)}
        <nav id="wrap" className={isHidden ?"wrap open": 'wrap' }>
           <div id="shortMenu" className="closed show">
            <button onClick={() => onState()} className="burger_menu_btn" id="burger_btn">
                <span id="spanMenu"></span>
                <span id="spanMenu2"></span>
                <span id="spanMenu3"></span>
            </button>
            <ul id="menu" className="menu">
            <li><Link to="/rus">Главная</Link></li>
            <li><Link to='/rus/services'>Услуги</Link></li>
            <li><Link to="/rus/gallary">Галерея</Link></li>
            <li><Link to="/rus/contacts">Контакты</Link></li>
            <li><Link to="/rus/articelone">Все об окнах</Link></li>
            </ul>
        </div>
            <FullMenu />
        </nav>
        </>
    );
}
function NavMenu () {
    return (
        <div className="navBtn"> 
        <NavWrap />
        <BackDropLogin />
        </div>
    );
}

export default NavMenu;