import glass from './../../img/forsite/Articels/changeglass.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, { useState } from "react";
import FirstScreenRu from "../../FirstScreenRu/ContainerOne";
import FiveScreenRu from '../../FiveScreenRu/FiveScreen';
import '../../Pages/Services.css';
import Phone2 from '../../img/phone3.png';
import '../../FirstScreenRu/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../../App';
import photoInstaram from '../../img/instagram.png'; 

function Lang () {
    const [isLang, setLang] = useState(false);
    const {isScroll} = useChange();

    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>RU</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelthree'>UA</Link> :''} </button></div>
        </div>
        </>
    )
}
function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
function Tel () {
        return (
            <div className="tel">
                <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
            </div>
              );
    }
    
    function LangTel () {
    const {isScroll, setScroll} = useChange();
    
        window.addEventListener('scroll', () => {
            let scrolled = window.pageYOffset;
            if (scrolled > 150) {
                setScroll(true);
            }
            if (scrolled < 150) {
                setScroll(false);
            }
        })
    
        return (
            <div className={isScroll ? 'ContainerFixed' : 'none'}>
            <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                <Tel />
                <Lang />
            </div>
            </div>
        );
    }


function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Замена стеклопакета</h2>
            <img className="quilon" src={glass} title='фурнітура' alt="фурнітура"/>
            <p>
            Назначение стеклопакетов – обеспечить помещение естественным солнечным светом и теплом в зимнее время года. Чем больше окно, тем больше светового комфорта. Большое окно визуально увеличивает размеры помещения, добавляя окружающую природу в интерьер.
            </p>
            <p>
            Стеклопакет – это герметичное соединение двух или более листов стекла в единую конструкцию.
            </p>
            <p>
          
            Причиной замены стеклопакета может быть лучше утеплить дом (квартиру), поставив энергосберегающие стеклопакеты. В результате чего можно хорошо сэкономить, если стоит индивидуальный счетчик.
            </p>
            <p>
            Основной причиной замены стеклопакета есть трещины, которые могут возникать от удара или резкого перепада температуры в комнате. К примеру, если на улице минусовая температура и ставят горячий чайник у окна внутри дома (квартиры).
            </p>
            <p>
            Также запотевают пакеты внутри. Причиной является разгерметизация пакета.
            </p>
            <p>
            При замене стеклопакета следует обратить внимание на правильное измерение пакета. От начальных показателей нужно отнять 10 мм.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreenRu />
        </div>
        <Furnitura />
        <FiveScreenRu />
        </div>
    );
}
export default ArticelTwo;