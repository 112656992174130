import React from 'react';
import './General_img.css';
import Foto9 from '../img/foto9.jpg';
import Furnitura3 from '../img/furnitura3.jpg';
import { Link } from 'react-router-dom';

function Block () {
    return (
        <div className="block">
            <h3>Для чего нужно менять уплотнитель</h3>
        </div>
    );
}
function BlockOneImgP () {
    return (
        <div>
            <img src={Foto9} alt="foto9"/>
            <p className="special">Если в Вашем доме или квартире установлены металлопластиковые окна, то Вы, скорее всего, замечали, что в холодное время года, при полностью закрытом положении окна и максимальном прижатии створки к раме, имеет место небольшое продувание между рамой и створкой... </p>
        </div>
    );
}
function BlockOneCheck () {
    return (
        <div className="check">
            <Link to="/rus/articelone">Подробнее...</Link>
        </div>
    );
}
function BlockOneFromGeneral () {
    return (
        <div className="block_1">
            <Block />
            <BlockOneImgP />
            <BlockOneCheck />
        </div>
    );
}
function BlockTwo () {
    return (
        <div className="block">
             <h3>Ремонт фурнитры</h3>
        </div>
    );
}
function BlockTwoImgP () {
    return (
        <div>
            <img src={Furnitura3} alt="foto9"/>
            <p className="special">Фурнитура, как и любой другой механизм, нуждается в постоянном уходе. Для этого необходимо периодически смазывать маслом (без содержания смолы и кислоты) и осматривать все подвижные элементы на наличие дефектов. Однако следует отметить, что впоследствии из окон может продувать...</p>
        </div>
    );
}
function BlockTwoCheck () {
    return (
        <div className="check">
            <Link to="/rus/articeltwo">Подробнее...</Link>
        </div>
    );
}
function BlockTwoFromGeneral () {
    return (
        <div className="block_2">
            <BlockTwo />
            <BlockTwoImgP />
            <BlockTwoCheck />
        </div>
    );
}
function GeneralImg () {
    return (
        <div className="general_img">
             <BlockOneFromGeneral />
             <BlockTwoFromGeneral />
         </div>
    );
}

export default GeneralImg;