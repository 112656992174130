import Foto1 from '../img/forsite/foto1.jpg';
import Foto2 from '../img/forsite/foto2.jpg';
import Foto3 from '../img/forsite/foto3.jpg';
import Foto4 from '../img/forsite/foto4.jpg';
import Foto5 from '../img/forsite/foto5.jpg';
import Foto6 from '../img/forsite/foto6.jpg';
import Foto7 from '../img/forsite/foto7.jpg';
import Foto8 from '../img/forsite/foto8.png';
import Foto9 from '../img/forsite/foto9.jpg';
import Foto10 from '../img/forsite/foto10.jpg';
import Foto11 from '../img/forsite/foto11.png';
import Foto12 from '../img/forsite/foto12.jpg';
import FirstScreen from "../FirstScreen/ContainerOne";
import FiveScreen from '../FiveScreen/FiveScreen';
import '../Pages/Services.css';
import '../Pages/Services.css';
import Phone2 from '../img/phone3.png';
import React, { useState } from "react";
import '../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useChange } from '../../App';
import photoInstaram from '../img/instagram.png'; 

function Lang () {
     const [isLang, setLang] = useState(false);
     const {isScroll} = useChange();
 
     const changeLang = () => {
         setLang(isLang => !isLang);
     }
    
     return (
         <>
         <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
         <div className="lang">
           <button onClick={changeLang} className="select"><span>UA</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
           <div className={isScroll? 'enchange' : ''}> <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/rus/gallary'>RU</Link> :''} </button></div>
         </div>
         </>
     )
 }
 function Instargam() {
    return (
        <Link to='https://instagram.com/spravni_vikna?igshid=ZDdkNTZiNTM='>
        <img className='instargam' src={photoInstaram} alt='instagram' title='instargam'/>
        </Link>
    )
}
 function Tel () {
         return (
             <div className="tel">
                 <Link to="tel:+38(063)4695007"> <img src={Phone2} alt="no painting"/> <span>(063) 469 50 07</span></Link> 
             </div>
               );
     }
     
     function LangTel () {
     const {isScroll, setScroll} = useChange();
     
         window.addEventListener('scroll', () => {
             let scrolled = window.pageYOffset;
             if (scrolled > 150) {
                 setScroll(true);
             }
             if (scrolled < 150) {
                 setScroll(false);
             }
         })
     
         return (
             <div className={isScroll ? 'ContainerFixed' : 'none'}>
             <div className={isScroll ? "fixedlangTel": 'langTel' }>
                <Instargam />
                 <Tel />
                 <Lang />
             </div>
             </div>
         );
     }

function Imgphoto() {
    const [isPhoto1, setPhoto1] = useState(false);
    const [isPhoto2, setPhoto2] = useState(false);
    const [isPhoto3, setPhoto3] = useState(false);
    const [isPhoto4, setPhoto4] = useState(false);
    const [isPhoto5, setPhoto5] = useState(false);
    const [isPhoto6, setPhoto6] = useState(false);
    const [isPhoto7, setPhoto7] = useState(false);
    const [isPhoto8, setPhoto8] = useState(false);
    const [isPhoto9, setPhoto9] = useState(false);
    const [isPhoto10, setPhoto10] = useState(false);
    const [isPhoto11, setPhoto11] = useState(false);
    const [isPhoto12, setPhoto12] = useState(false);

    const fullPhoto1 = () => {
        setPhoto1(isPhoto1 => !isPhoto1);

    }
    const fullPhoto2 = () => {
         setPhoto2(isPhoto2 => !isPhoto2);
    }
    const fullPhoto3 = () => {
         setPhoto3(isPhoto3 => !isPhoto3);
    }
    const fullPhoto4 = () => {
         setPhoto4(isPhoto4 => !isPhoto4);
    }
    const fullPhoto5 = () => {
         setPhoto5(isPhoto5 => !isPhoto5);
    }
    const fullPhoto6 = () => {
         setPhoto6(isPhoto6 => !isPhoto6);
    }
    const fullPhoto7 = () => {
         setPhoto7(isPhoto7 => !isPhoto7);
    }
    const fullPhoto8 = () => {
         setPhoto8(isPhoto8 => !isPhoto8);
    }
    const fullPhoto9 = () => {
         setPhoto9(isPhoto9 => !isPhoto9);
    }
    const fullPhoto10 = () => {
         setPhoto10(isPhoto10 => !isPhoto10);
    }
    const fullPhoto11 = () => {
         setPhoto11(isPhoto11 => !isPhoto11);
    }
    const fullPhoto12 = () => {
         setPhoto12(isPhoto12 => !isPhoto12);
    }
   
    return (
        <>
        <div className="containerPhoto" >
        <h2 className="galaryh2">Наші роботи</h2>
        <div className="allphoto" >
            <div className="onerow">
                <img onClick={fullPhoto1} className={isPhoto1 ? 'changephoto' :'allphoto'} src={Foto1} alt="foto1" />
                <img onClick={fullPhoto2} className={isPhoto2 ? 'changephoto' :'allphoto'} src={Foto2} alt="foto2" />
                <img onClick={fullPhoto3} className={isPhoto3 ? 'changephoto' :'allphoto'} src={Foto3} alt="foto3" />
               <img onClick={fullPhoto4} className={isPhoto4 ? 'changephoto' :'allphoto'} src={Foto4} alt="foto4" />
            </div>
            <div className="tworow">
                    <img onClick={fullPhoto5} className={isPhoto5 ? 'changephoto' :'allphoto'} src={Foto5} alt="foto5" />
                    <img onClick={fullPhoto6} className={isPhoto6 ? 'changephoto' :'allphoto'} src={Foto6} alt="foto6" />
                    <img onClick={fullPhoto7} className={isPhoto7 ? 'changephoto' :'allphoto'} src={Foto7} alt="foto7" />
                    <img onClick={fullPhoto8} className={isPhoto8 ? 'changephoto' :'allphoto'} src={Foto8} alt="foto8" />
            </div>
            <div className="threerow">
                    <img onClick={fullPhoto9} className={isPhoto9 ? 'changephoto' :'allphoto'} src={Foto9} alt="foto9" />
                    <img onClick={fullPhoto10} className={isPhoto10 ? 'changephoto' :'allphoto'} src={Foto10} alt="foto10" />
                    <img onClick={fullPhoto11} className={isPhoto11 ? 'changephoto' :'allphoto'} src={Foto11} alt="foto11" />
                    <img onClick={fullPhoto12} className={isPhoto12 ? 'changephoto' :'allphoto'} src={Foto12} alt="foto12" />
            </div>
        </div>
    </div>
        </>
    );
}

function Gallary() {
    return (
     <div className="body_wrapper">
        <div className="first_screen">
        <LangTel />
        <FirstScreen />
        </div>
        <Imgphoto />
        <FiveScreen />

        </div>
       
    );
}
export default Gallary;